<template>
  <!-- detail delivery orders -->
  <div class="w-full vx-row">
    <vs-table
      :style="{ width: '100%' }"
      :sst="false"
      :data="dataViews"
      :total="dataViews.length"
      :searchable="false"
    >
      <template slot="thead">
        <vs-th>Code</vs-th>
        <vs-th>Name</vs-th>
        <vs-th>Unit</vs-th>
        <vs-th>Quantity</vs-th>
        <vs-th>Adjusted Quantity</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tk" :key="indextr" v-for="(tk, indextr) in data">
          <vs-td>
            {{ tk.item_code }}
          </vs-td>
          <vs-td>
            {{ tk.item_name }}
          </vs-td>
          <vs-td>
            {{ tk.item_unit }}
          </vs-td>
          <vs-td>
            {{ tk.quantity }}
          </vs-td>
          <vs-td>
            {{ tk.adjusted_quantity }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div class="vx-row mt-2 w-3/4 flex" v-if="!isNew">
      <div class="vx-col mt-2 w-full flex">
        <span class="w-1/3">Date Pickup</span>
        <div class="w-2/3">
          <vs-input
            class="w-full"
            v-model="dateTp"
            type="date"
            :min="dateNow"
            v-validate="'required'"
            @input="FindTransportPlan()"
          />
        </div>
      </div>
      <div class="vx-col mt-2 w-full flex">
        <span class="w-1/3">Transport Plan</span>
        <div class="w-2/3">
          <multiselect
            class="selectExample"
            v-model="transportPlanSelected"
            :options="transportPlan"
            :multiple="false"
            :allow-empty="true"
            :max-height="400"
            :searchable="true"
            :customLabel="customLableTransportPlan"
          />
        </div>
      </div>
      <div class="vx-row mt-2 w-full">
        <span class="w-1/3"></span>
        <div class="w-2/3 flex justify-center">
          <vs-button v-on:click="submit()" class="w-1/3" color="primary">
            Assign
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    dataViews: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: () => {},
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.getTransportPlan();
  },
  data() {
    return {
      table2: this.tableDefaultState(),
      dateTp: moment().format("YYYY-MM-DD"),
      dateNow: moment().format("YYYY-MM-DD"),
      transportPlan: [],
      transportPlanSelected: null,
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    customLableTransportPlan(option) {
      return `${option.shipment_number} - ${option.vehicle_number}`;
    },
    FindTransportPlan() {
      this.getTransportPlan();
    },

    getTransportPlan() {
      this.$vs.loading();
      this.transportPlan = [];
      this.$http
        .get("/api/wms/v1/transport-pickup/vehicle-pickup", {
          params: {
            length: this.table2.length,
            page: this.table2.page,
            search: this.table2.search,
            order: this.table2.order,
            sort: this.table2.sort,
            date: this.dateTp,
          },
        })
        .then((resp) => {
          console.log("sssss", resp);
          if (resp.code == 200) {
            this.transportPlan = resp.data ? resp.data.records : [];
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
    submit() {
      this.$vs.dialog({
        type: "confirm",
        color: "primary",
        title: "Confirm",
        text: "Are you sure to assign this transport plan?",
        acceptText: "Yes",
        accept: () => {
          this.SubmitForm();
        },
      });
    },
    SubmitForm() {
      let tpData = [
        {
          id: this.transportPlanSelected.id,
          delivery_plan_date: this.transportPlanSelected.delivery_plan_date,
          shipment_number: this.transportPlanSelected.shipment_number,
          total_volume: this.transportPlanSelected.total_volume,
          vehicle_id: this.transportPlanSelected.vehicle_id,
          warehouse_id: this.transportPlanSelected.warehouse_id,
        },
      ];
      this.$vs.loading();
      this.$http
        .post("/api/wms/v1/transport-pickup/assigned-pickup", {
          code: this.data.code,
          id: this.data.id,
          customer_id: this.data.customer_id,
          customer_name: this.data.customer_name,
          customer_code: this.data.customer_code,
          customer_address: this.data.customer_address,
          sales_person_id: this.data.sales_person_id,
          sales_person_name: this.data.sales_person_name,
          sales_person_mobile: this.data.sales_person_mobile,
          zone_id: this.data.zone_id,
          zone_name: this.data.zone_name,
          zone_code: this.data.zone_code,
          pickup_date: this.dateTp,
          transport_plan: tpData,
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Reservation has been added",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.$emit("close");
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }

          this.$vs.loading.close();
        });
    },
  },
};
</script>
<style>
.vl {
  border-left: 6px solid black;
  height: 100%;
}
</style>
